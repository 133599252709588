import { ValueSerializer, param } from 'type-route';

import { BillingInterval } from '../../../../types/BillingInterval';
import { BillingPlanName } from '../../../../types/BillingPlanName';

const billingPlanParam: ValueSerializer<BillingPlanName> = {
  parse(plan) {
    return plan as BillingPlanName;
  },
  stringify(value) {
    return String(value);
  },
};

const billingIntervalParam: ValueSerializer<BillingInterval> = {
  parse(interval) {
    return interval as BillingInterval;
  },
  stringify(value) {
    return String(value);
  },
};

export const billingFullScreenOverlayParams = {
  slug: param.path.string,
  plan: param.query.ofType(billingPlanParam),
  interval: param.query.ofType(billingIntervalParam),
  quantity: param.query.number,
};
