import { ValueSerializer } from 'type-route';

// Add other sections as needed
type AccountSectionType = 'responsibility-specializations-surface';

export const accountSection: ValueSerializer<AccountSectionType> = {
  parse(section) {
    return section as AccountSectionType;
  },
  stringify(section) {
    return section;
  },
};
