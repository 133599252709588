import React from 'react';
import { preventDefaultLinkClickBehavior } from 'type-route';

import useIdRegistry from '../../../../hooks/useIdRegistry';
import { session, useRouterHelper } from '../../../application/publicApplication/applicationRouter';
import Icon from '../../display/icons/Icon';
import {
  HiddenText,
  IconWrapper,
  ImageButton,
  LoaderIconWrapper,
  getButtonComponent,
} from './ButtonStyles';
import { DefaultButtonProps } from './ButtonTypes';

const DefaultButton = (props: DefaultButtonProps) => {
  const routeState = useRouterHelper();

  useIdRegistry(props.id);

  const {
    buttonType = 'primary',
    className,
    disabled = false,
    fullWidth,
    iconColor,
    id,
    iconImage,
    size = 'md',
    text,
    iconName,
    iconPosition = 'left',
    iconRef,
    iconWeight = 'regular',
    dataFor,
    onMouseEnter,
    onMouseLeave,
    draggable = false,
    dataTestid,
  } = props;

  if (props.behavesAs === 'a') {
    const { href, target } = props;

    const ButtonComponent = getButtonComponent(buttonType);
    return (
      <ButtonComponent
        as='a'
        buttonType={buttonType}
        className={className}
        data-for={dataFor}
        data-testid={dataTestid}
        data-tip
        disabled={disabled}
        draggable={draggable}
        fullWidth={fullWidth}
        href={href}
        iconColor={iconColor}
        iconPosition={iconPosition}
        id={id}
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          if (disabled) {
            event.preventDefault();
            return;
          }

          /*
          type-route: Temporarily needed while app is being migrated to fully use type-route library.
          When we are fully migrated we will require a route to be passed from the router instead of href
          */
          if (routeState && preventDefaultLinkClickBehavior(event)) {
            const button = event.target as HTMLAnchorElement;
            button.blur();

            session.push(href);
          }
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        size={size}
        target={target}
      >
        {iconName && (
          <>
            <IconWrapper hasText={Boolean(text)} iconPosition={iconPosition} ref={iconRef}>
              <Icon name={iconName} size='xs' weight={iconWeight} />
            </IconWrapper>
            {text && text}
          </>
        )}
        {!iconName && text}
      </ButtonComponent>
    );
  }
  const { loading, onClick, type } = props;
  const ButtonComponent = getButtonComponent(buttonType);
  return (
    <ButtonComponent
      as='button'
      buttonType={buttonType}
      className={className}
      data-for={dataFor}
      data-testid={dataTestid}
      data-tip
      disabled={disabled}
      draggable={draggable}
      fullWidth={fullWidth}
      iconColor={iconColor}
      iconPosition={iconPosition}
      id={id}
      loading={loading}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      size={size}
      type={type}
    >
      {iconName && (
        <>
          <IconWrapper hasText={Boolean(text)} iconPosition={iconPosition} ref={iconRef}>
            {loading ? (
              <Icon name='spinner-third' size='xs' spinSpeed='fast' weight={iconWeight} />
            ) : (
              <Icon name={iconName} size='xs' weight={iconWeight} />
            )}
          </IconWrapper>
          {text && <span>{text}</span>}
        </>
      )}
      {iconImage && (
        <>
          <ImageButton src={iconImage} />
        </>
      )}
      {!iconName && loading && (
        <>
          <LoaderIconWrapper>
            <Icon name='spinner-third' size='xs' spinSpeed='fast' weight='solid' />
          </LoaderIconWrapper>
          <HiddenText>
            <span>{text}</span>
          </HiddenText>
        </>
      )}
      {!iconName && !loading && <span>{text}</span>}
    </ButtonComponent>
  );
};

export default DefaultButton;
