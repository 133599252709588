import { darken } from 'polished';
import { SaguaroTheme } from 'saguaro';
import styled, { DefaultTheme, css } from 'styled-components';

import { TruncatedText } from '../../../design_system/Triage/TruncatedText';
import { forceCapitalization } from '../../../helpers/cssTranslation';
import { fontSm5 } from '../../../styled/TypeSystem';
import { ButtonSize, ButtonType, IconPosition } from './ButtonTypes';

const primaryDisabledState = css`
  background-color: ${({ theme: { vars } }) => vars.foundationBase3};
  border-color: ${({ theme: { vars } }) => vars.foundationBase3};
  color: ${({ theme: { vars } }) => vars.textDisabled};
  cursor: not-allowed;
`;

const secondaryDisabledState = css`
  background-color: ${({ theme: { vars } }) => vars.foundationBase3};
  border-color: ${({ theme: { vars } }) => vars.foundationBase3};
  color: ${({ theme: { vars } }) => vars.textDisabled};
  cursor: not-allowed;
`;

const tertiaryDisabledState = css`
  background-color: ${({ theme: { vars } }) => vars.foundationBase3};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
  color: ${({ theme: { vars } }) => vars.textDisabled};
  cursor: not-allowed;
`;

export const BaseButtonStyles = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    // 'loading' is a valid html attribute and as such the framework will pass it to the react node by default.
    // Here we're filtering this property out to disable that behavior and remove the corresponding warning in the console
    !['loading'].includes(prop) && defaultValidatorFn(prop),
})<{
  size: ButtonSize;
  loading?: boolean;
  iconColor?: string;
  iconPosition: IconPosition;
  buttonType?: ButtonType;
  fullWidth?: boolean;
  disabled?: boolean;
}>`
  border: ${({ theme: { constants } }) => constants.borderWidthSm} solid
    ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusEndcap};
  color: ${({ theme: { vars } }) => vars.textSurface};
  position: relative;
  min-width: 6rem;
  ${({ size, theme }) => getButtonSize(size, theme)};
  cursor: pointer;
  padding: ${({ theme: { constants } }) => `${constants.spacerSm2} ${constants.spacerMd2}`};
  text-align: center;
  font-weight: ${({ theme: { constants } }) => constants.fontRegular};
  display: inline-flex;
  flex-direction: ${({ iconPosition }) => (iconPosition === 'left' ? 'row' : 'row-reverse')};
  align-items: center;
  justify-content: center;
  width: ${({ fullWidth }) => fullWidth && '100%'};
  box-sizing: border-box;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  svg {
    color: ${({ buttonType, theme, iconColor, disabled, loading }) =>
      getIconColor(buttonType, theme, iconColor, disabled, loading)};
  }

  &:hover {
    svg {
      color: ${({ buttonType, theme, iconColor, disabled, loading }) => {
        if (disabled || loading) return;
        return darken(0.1, getIconColor(buttonType, theme, iconColor));
      }};
    }
  }
  ${fontSm5};
  ${TruncatedText({})};
  ${forceCapitalization};
`;

export const PrimaryButton = styled(BaseButtonStyles)<{ loading?: boolean }>`
  background-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  color: ${({ theme: { vars } }) => vars.textSurface};

  &:focus-visible {
    outline: solid 2px #005fcc;
    box-sizing: box-content;
  }

  ${({ loading }) =>
    loading &&
    css`
      background-color: ${({ theme: { vars } }) => vars.foundationBase3};
      border-color: ${({ theme: { vars } }) => vars.foundationBase3};
      color: ${({ theme: { vars } }) => vars.textDisabled};
      cursor: default;
    `}

  ${({ loading }) =>
    !loading &&
    css`
      &:hover,
      &:focus {
        background-color: ${({ theme: { vars } }) => vars.accentPrimaryHover};
        border-color: ${({ theme: { vars } }) => vars.accentPrimaryHover};
        color: ${({ theme: { vars } }) => vars.textSurface};
        text-decoration: none;
      }
    `}

 ${({ disabled }) =>
    disabled &&
    css`
      ${primaryDisabledState};

      &:hover,
      &:focus {
        ${primaryDisabledState};
      }
    `}
`;

export const SecondaryButton = styled(BaseButtonStyles)<{ loading?: boolean }>`
  background-color: ${({ theme: { vars } }) => vars.foundationSurface1};
  color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
  border-color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};

  &:focus-visible {
    outline: solid 2px #005fcc;
    box-sizing: box-content;
  }

  ${({ loading }) =>
    loading &&
    css`
      background-color: ${({ theme: { vars } }) => vars.foundationBase3};
      border-color: ${({ theme: { vars } }) => vars.borderDisabled};
      color: ${({ theme: { vars } }) => vars.textDisabled};
      cursor: default;
    `}

  ${({ loading }) =>
    !loading &&
    css`
      &:hover,
      &:focus {
        background-color: ${({ theme: { vars } }) => vars.accentSubdued1};
        color: ${({ theme: { vars } }) => vars.accentPrimaryDefault};
      }
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      ${secondaryDisabledState};

      &:hover,
      &:focus {
        ${secondaryDisabledState};
      }
    `}
`;

export const TertiaryButton = styled(BaseButtonStyles)<{ loading?: boolean }>`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  color: ${({ theme: { vars } }) => vars.textDefault};

  &:focus-visible {
    outline: solid 2px #005fcc;
    box-sizing: box-content;
  }

  ${({ loading }) =>
    loading &&
    css`
      background-color: ${({ theme: { vars } }) => vars.foundationBase3};
      border: ${({ theme: { constants, vars } }) =>
        `${constants.borderWidthSm} solid ${vars.borderSurface2}`};
      color: ${({ theme: { vars } }) => vars.textDisabled};
      cursor: default;
    `}

  ${({ loading }) =>
    !loading &&
    css`
      &:hover,
      &:focus {
        background-color: ${({ theme: { vars } }) => vars.foundationBase2};
        border: ${({ theme: { constants, vars } }) =>
          `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
        color: ${({ theme: { vars } }) => vars.textDefault};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      ${tertiaryDisabledState};

      &:hover,
      &:focus {
        ${tertiaryDisabledState};
      }
    `}
`;

export const BrandButton = styled(BaseButtonStyles)<{ loading?: boolean }>`
  background-color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthLg} solid ${vars.trainualBrandGreenMedium}`};
  color: ${({ theme: { vars } }) => vars.textSurface};

  // DS Override - used custom color from another button types
  &:focus-visible {
    outline: solid 2px #005fcc;
    box-sizing: box-content;
  }

  ${({ loading }) =>
    loading &&
    css`
      background-color: ${({ theme: { vars } }) => vars.foundationBase3};
      border: ${({ theme: { constants, vars } }) =>
        `${constants.borderWidthLg} solid ${vars.borderSurface2}`};
      color: ${({ theme: { vars } }) => vars.textSurface};
      cursor: default;
    `}

  ${({ loading }) =>
    !loading &&
    css`
      &:hover,
      &:focus {
        background-color: ${({ theme: { vars } }) => vars.trainualBrandBlueStrong};
        border: ${({ theme: { constants, vars } }) =>
          `${constants.borderWidthLg} solid ${vars.trainualBrandGreenSubdued}`};
        color: ${({ theme: { vars } }) => vars.textSurface};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      ${tertiaryDisabledState};

      &:hover,
      &:focus {
        ${tertiaryDisabledState};
      }
    `}
`;

export const IconWrapper = styled.div<{ hasText: boolean; iconPosition: IconPosition }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ hasText, theme, iconPosition }) =>
    hasText && iconPosition === 'left' ? theme.constants.spacerSm3 : 0};
  margin-left: ${({ hasText, theme, iconPosition }) =>
    hasText && iconPosition === 'right' ? theme.constants.spacerSm3 : 0};
  position: relative;
`;

export const HiddenText = styled.span`
  visibility: hidden; /* Holds button size when hiding text */
`;

export const LoaderIconWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
`;

export const ImageButton = styled.img`
  width: ${({ theme: { constants } }) => constants.height2xs};
  height: ${({ theme: { constants } }) => constants.height2xs};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const getButtonSize = (size: ButtonSize, theme: DefaultTheme) => {
  const { heightSm, heightMd, heightLg } = theme.constants;

  switch (size) {
    case 'sm':
      return {
        height: heightSm,
      };
    case 'md':
      return {
        height: heightMd,
      };
    case 'lg':
      return {
        height: heightLg,
      };
  }
};

export const getIconColor = (
  buttonType: ButtonType | undefined,
  theme: SaguaroTheme,
  iconColor?: string,
  disabled?: boolean,
  loading?: boolean
): string => {
  if (disabled || loading) return '';
  switch (buttonType) {
    case 'tertiary':
      return iconColor || theme.vars.textDefault;
    case 'secondary':
      return iconColor || theme.vars.accentPrimaryDefault;
    case 'primary':
    default:
      return iconColor || theme.vars.textSurface;
  }
};

export const getButtonComponent = (buttonType: ButtonType) => {
  switch (buttonType) {
    case 'tertiary':
      return TertiaryButton;
    case 'secondary':
      return SecondaryButton;
    case 'primary':
      return PrimaryButton;
    case 'brand':
      return BrandButton;
  }
};
