import { throttle } from 'lodash';
import { useEffect, useState } from 'react';

import {
  mediaBreakpoint2xl,
  mediaBreakpointLg,
  mediaBreakpointMd,
  mediaBreakpointSm,
  mediaBreakpointXl,
} from '../components/styled/Breakpoint';

const useWindowResize = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < mediaBreakpointMd,
    isTablet: window.innerWidth >= mediaBreakpointMd && window.innerWidth < mediaBreakpointLg,
    isDesktop: window.innerWidth >= mediaBreakpointLg,
    isDesktopXl: window.innerWidth >= mediaBreakpointXl,
    isDesktop2xl: window.innerWidth >= mediaBreakpoint2xl,
    isExtraSmWindow: window.innerWidth <= mediaBreakpointSm,
  });

  useEffect(() => {
    const handleResize = () =>
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < mediaBreakpointMd,
        isTablet: window.innerWidth >= mediaBreakpointMd && window.innerWidth < mediaBreakpointLg,
        isDesktop: window.innerWidth >= mediaBreakpointLg,
        isDesktopXl: window.innerWidth >= mediaBreakpointXl,
        isDesktop2xl: window.innerWidth >= mediaBreakpoint2xl,
        isExtraSmWindow: window.innerWidth <= mediaBreakpointSm,
      });

    const throttled = throttle(handleResize, 100);

    window.addEventListener('resize', throttled);

    return () => {
      window.removeEventListener('resize', throttled);
    };
  }, []);

  return dimensions;
};

export default useWindowResize;
