import React, { ReactElement, ReactNode, createContext, useState } from 'react';

type Configs = { [key: string]: string };

type PublicConfigsProviderProps = {
  children: ReactNode;
};

interface PublicConfigsContext {
  configs: Configs;
  setConfigs: React.Dispatch<React.SetStateAction<Configs>>;
}

export const PublicConfigsContext = createContext({} as PublicConfigsContext);

const PublicConfigsProvider = ({ children }: PublicConfigsProviderProps): ReactElement => {
  const [configs, setConfigs] = useState<Configs>({});

  return (
    <PublicConfigsContext.Provider value={{ configs, setConfigs }}>
      {children}
    </PublicConfigsContext.Provider>
  );
};

export { PublicConfigsProvider };
