import React, { ReactNode } from 'react';
import { PaletteOption, SaguaroThemeProvider } from 'saguaro';

import useStyleMode from '../hooks/useStyleMode';
import StyleMode from '../types/StyleMode';
import IconScene from './design_system/display/icons/IconScene';

type Props = {
  // Style mode is optional to allow overriding the style mode in scenarios where the current user info is not available
  // This is useful for the public share, storybook and vite tests.
  mode?: StyleMode;
  children: ReactNode;
  accentPalette: PaletteOption | null;
  accentColor: string;
};

const StyleScene = ({ children, accentPalette, accentColor, mode = 'light' }: Props) => {
  const styleMode = useStyleMode(mode);

  return (
    <IconScene>
      <SaguaroThemeProvider brandOption={accentPalette || accentColor} mode={styleMode}>
        {children}
      </SaguaroThemeProvider>
    </IconScene>
  );
};

export default StyleScene;
