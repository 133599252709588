import React, { ReactElement, ReactNode, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

type IReactInstanceProvider = {
  children: ReactNode;
};

interface IReactInstanceContext {
  id: number | string;
}

const ReactInstanceContext = React.createContext<IReactInstanceContext>(
  {} as IReactInstanceContext
);
export const useReactInstance = () => useContext(ReactInstanceContext);

const ReactInstanceProvider = ({ children }: IReactInstanceProvider): ReactElement => {
  return (
    <ReactInstanceContext.Provider value={{ id: uuidv4() }}>
      {children}
    </ReactInstanceContext.Provider>
  );
};

export { ReactInstanceProvider };
