// Numbers
export const mediaBreakpointSm = 576;
export const mediaBreakpointMd = 768;
export const mediaBreakpointLg = 992;
export const mediaBreakpointXl = 1200;
export const mediaBreakpoint2xl = 1600;

// Pixels
export const mediaBreakpointPxSm = '576px';
export const mediaBreakpointPxMd = '768px';
export const mediaBreakpointPxLg = '992px';
export const mediaBreakpointPxXl = '1200px';
export const mediaBreakpointPx2xl = '1600px';

export type BreakpointsNumbersType =
  | typeof mediaBreakpointSm
  | typeof mediaBreakpointMd
  | typeof mediaBreakpointLg
  | typeof mediaBreakpointXl
  | typeof mediaBreakpoint2xl;
