import { ValueSerializer, param } from 'type-route';

import { AddonName } from '../../../../types/Addon';

const nameParam: ValueSerializer<AddonName> = {
  parse(name) {
    return name as AddonName;
  },
  stringify(value) {
    return String(value);
  },
};

export const addonFullScreenOverlayParams = {
  slug: param.path.string,
  name: param.path.ofType(nameParam),
};
