import { useContext } from 'react';

import { PublicConfigsContext } from '../contexts/PublicConfigsContext';

const usePublicConfigs = () => {
  const { configs: configs } = useContext(PublicConfigsContext);

  if (!configs) {
    throw new Error('Configs not initialized');
  }

  return { configs };
};

export default usePublicConfigs;
