import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import ReactOnRails from 'react-on-rails';

import { Data } from './types';

const getCookie = (name: string | null) => {
  const match = document.cookie.match(`(?<=^|;\\s*)${name}=([^;]*)`);
  return match ? decodeURIComponent(match[1]) : null;
};

const rawBaseQuery = (includeMobileAuth: boolean) =>
  fetchBaseQuery({
    baseUrl: process.env.RTK_QUERY_BASE_URL || '/',
    prepareHeaders: (headers) => {
      if (includeMobileAuth) {
        headers.append('Authorization', `${getCookie('mobileAuthToken')}`);
      } else {
        const authenticityHeaders = ReactOnRails.authenticityHeaders({});

        for (const authenticityHeadersKey in authenticityHeaders) {
          if (Object.prototype.hasOwnProperty.call(authenticityHeaders, authenticityHeadersKey)) {
            headers.append(authenticityHeadersKey, authenticityHeaders[authenticityHeadersKey]);
          }
        }
      }

      return headers;
    },
  });

export const fetchData =
  (includeMobileAuth = false) =>
  async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: Record<string, unknown>) => {
    const { data, error } = await rawBaseQuery(includeMobileAuth)(args, api, extraOptions);

    if (error) return { error, status: error.status };

    let responseData = data as Data;
    if (responseData !== null && 'data' in responseData) {
      responseData = responseData.data;
    }

    return { data: responseData };
  };
