import { useEffect } from 'react';

import idRegistry, { RegisteredId } from '../lib/idRegistry';
import usePublicConfigs from './usePublicConfigs';

const useIdRegistry = (id: RegisteredId) => {
  const { configs } = usePublicConfigs();
  const uniqueIdValidation = configs['UNIQUE_ID_VALIDATION'] === 'true';

  useEffect(() => {
    if (!uniqueIdValidation) {
      return;
    }

    idRegistry.get(id);
    return () => {
      idRegistry.resetKey(id);
    };
  }, [id, uniqueIdValidation]);
};

export default useIdRegistry;
