import { ValueSerializer } from 'type-route';

export type GroupTabType = 'overview' | 'content';

export const groupTab: ValueSerializer<GroupTabType> = {
  parse(tab) {
    return tab as GroupTabType;
  },
  stringify(tab) {
    return tab;
  },
};
