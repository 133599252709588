import React, { ReactElement, ReactNode, createContext } from 'react';

type Designation = 'trainual' | 'account' | 'mobile';

type ContentStyleDesignationProviderProps = {
  children: ReactNode;
  designation: Designation;
};

export const ContentStyleDesignationContext = createContext<Designation>('trainual');

const ContentStyleDesignationProvider = ({
  children,
  designation,
}: ContentStyleDesignationProviderProps): ReactElement => {
  return (
    <ContentStyleDesignationContext.Provider value={designation}>
      {children}
    </ContentStyleDesignationContext.Provider>
  );
};

export { ContentStyleDesignationProvider };
