import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { uniq } from 'lodash';

export const FA_BRAND_ICONS = uniq(Object.values(fab));
export const FA_SOLID_ICONS = uniq(Object.values(fas));

export const initializeFonts = () => {
  const libraryArray = [fas, far, fal, fab, fad];

  // There is an issue where occasionally some of the fonts will be undefined. If this happens the entire app will fail
  // to load. This is a bit of a hack, but it will prevent the entire app from failing to load and instead certain icons
  // will simply not appear. More research is needed to determine the root cause.
  library.add(...libraryArray.filter((icon) => icon !== undefined));
};
