import { IconName, IconPrefix, IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { useTheme } from 'styled-components';

import { IconSize, IconWeight } from './IconTypes';

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<{ $iconSize?: IconSize }>`
  ${({ $iconSize }) => getIconSize($iconSize || 'xs')};
  pointer-events: none;
`;

const FastSpinIcon = styled(StyledFontAwesomeIcon)`
  -webkit-animation: fa-spin 0.75s infinite linear !important;
  animation: fa-spin 0.75s infinite linear !important;
`;

const SlowSpinIcon = styled(StyledFontAwesomeIcon)`
  -webkit-animation: fa-spin 1s infinite linear !important;
  animation: fa-spin 1s infinite linear !important;
`;

type TFontAwesomeProperties = {
  '--fa-primary-color'?: string;
  '--fa-secondary-color'?: string;
  '--fa-secondary-opacity'?: string;
};

export type WeightProps =
  | {
      weight: 'duotone';
      primaryColor?: string;
      secondaryColor?: string;
    }
  | {
      weight?: IconWeight;
      primaryColor?: never;
      secondaryColor?: never;
    };

export type Props = Pick<FontAwesomeIconProps, 'fixedWidth' | 'spin'> & {
  className?: string;
  name: IconName | 'none';
  size?: IconSize;
  spinSpeed?: 'slow' | 'fast';
} & WeightProps;

function getPrefix(prefixType: IconWeight): IconPrefix {
  switch (prefixType) {
    case 'regular':
      return 'far';
    case 'light':
      return 'fal';
    case 'solid':
      return 'fas';
    case 'brands':
      return 'fab';
    case 'duotone':
      return 'fad';
  }
}

export const getIconSize = (size: IconSize) => {
  switch (size) {
    case '2xs':
      return {
        'font-size': '12px',
        'max-width': '12px',
      };
    case 'xs':
      return {
        'font-size': '16px',
        'max-width': '16px',
      };
    case 'sm':
      return {
        'font-size': '20px',
        'max-width': '20px',
      };
    case 'md':
      return {
        'font-size': '24px',
        'max-width': '24px',
      };
    case 'lg':
      return {
        'font-size': '32px',
        'max-width': '32px',
      };
  }
};

const Icon = (props: Props) => {
  const {
    vars: { borderSurface2, textDefault },
  } = useTheme();
  const {
    className = '',
    fixedWidth,
    size = 'xs',
    name,
    primaryColor = textDefault,
    secondaryColor = borderSurface2,
    spinSpeed,
    spin,
    weight = 'regular',
  } = props;
  const shouldSpin = spin || Boolean(spinSpeed);
  const icon = [getPrefix(weight), name] as IconProp;

  const style: React.CSSProperties & TFontAwesomeProperties = {};
  style['--fa-primary-color'] = primaryColor;
  style['--fa-secondary-color'] = secondaryColor;
  style['--fa-secondary-opacity'] = '1';

  if (shouldSpin) {
    return spinSpeed === 'fast' ? (
      <FastSpinIcon
        $iconSize={size}
        aria-hidden='true'
        className={className}
        fixedWidth={fixedWidth}
        icon={icon}
        spin={shouldSpin}
        style={style}
      />
    ) : (
      <SlowSpinIcon
        $iconSize={size}
        aria-hidden='true'
        className={className}
        fixedWidth={fixedWidth}
        icon={icon}
        spin={shouldSpin}
        style={style}
      />
    );
  }

  return (
    <StyledFontAwesomeIcon
      $iconSize={size}
      aria-hidden='true'
      className={className}
      fixedWidth={fixedWidth}
      icon={icon}
      spin={shouldSpin}
      style={style}
    />
  );
};

export default Icon;
