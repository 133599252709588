import { ValueSerializer } from 'type-route';

export enum InternalIndexFeature {
  PDFExport = 'pdf_export',
}

const validFeatures = new Set(Object.values(InternalIndexFeature) as string[]);

export const internalIndexValueSerializer: ValueSerializer<InternalIndexFeature | null> = {
  parse: (feature: string) => {
    return validFeatures.has(feature) ? (feature as InternalIndexFeature) : null;
  },
  stringify: (feature: InternalIndexFeature | null) => {
    return feature || '';
  },
};
