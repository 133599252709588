// Keep the legacy dashboard stylesheet in sync with the react style mode
import { useEffect, useMemo, useState } from 'react';

import StyleMode from '../types/StyleMode';

const useStyleMode = (styleMode: StyleMode) => {
  const [currentStyleMode, setCurrentStyleMode] = useState(styleMode);

  // Any stylesheet with the attribute data-style-mode will only be active if the value matches the current style mode
  // for the account.
  const legacyDashboardStylesheets = useMemo(
    () => Array.from(document.querySelectorAll('[data-style-mode]') as NodeListOf<HTMLLinkElement>),
    []
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.body.style.opacity = '';
    }, 200);

    if (currentStyleMode !== styleMode) {
      // Temporarily set the body to be transparent to avoid flash of un-styled content.
      document.body.style.opacity = '0';

      legacyDashboardStylesheets.forEach((sheet) => {
        const mediaValue = sheet.dataset['styleMode'] === styleMode ? 'all' : 'none';
        if (sheet.media !== mediaValue) {
          sheet.media = mediaValue;
        }
      });
      setCurrentStyleMode(styleMode);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [styleMode, legacyDashboardStylesheets, currentStyleMode]);

  return styleMode;
};

export default useStyleMode;
