import { useTheme } from 'styled-components';

import { Size } from './BadgeTypes';

export const useBadgeStyles = (size: Size) => {
  let styles = {};
  const { constants } = useTheme();
  const { heightXs, spacerSm3 } = constants;
  switch (size) {
    case 'circle':
      styles = {
        height: '1rem',
        width: '1rem',
        display: 'inline-block',
      };
      break;
    case 'md':
      styles = {
        'padding-right': spacerSm3,
        'padding-left': spacerSm3,
        height: heightXs,
        display: 'block',
        'line-height': '1.5rem !important',
        'white-space': 'nowrap',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
      };
      break;
  }

  return styles;
};
