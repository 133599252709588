import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import { fetchData } from './helpers';

const dynamicPublicShareBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  return await fetchData()(args, api, extraOptions);
};

export default dynamicPublicShareBaseQuery;
