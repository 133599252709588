import React, { ReactElement, ReactNode, useContext, useState } from 'react';
import { ToastContent, ToastOptions, toast } from 'react-toastify';

import ToastNotificationWithStructuredContent from './design_system/display/ToastNotification/ToastNotificationWithStructuredContent';
import { useReactInstance } from './ReactInstanceContext';

export type FlashType = 'info' | 'warn' | 'warning' | 'error' | 'success';
export type CustomMessageOptions =
  | {
      hasCustomMessageStructure?: boolean;
      customTitle?: never;
    }
  | {
      hasCustomMessageStructure?: never;
      customTitle?: string | ReactNode;
    };

type CustomToastOptions = ToastOptions & CustomMessageOptions;
type FlashNotificationArgs = [FlashType, ToastContent, CustomToastOptions?];
type IFlashNotificationProvider = {
  children: ReactNode;
};

interface FlashNotificationContext {
  containerId: string | number;
  clearWaitingQueue: () => void;
  flash: (...args: FlashNotificationArgs) => void;
  dismissAll: () => void;
  flashLimit: number;
  setFlashLimit: React.Dispatch<React.SetStateAction<number>>;
}

const FlashNotificationContext = React.createContext<FlashNotificationContext>(
  {} as FlashNotificationContext
);
export const useFlashNotification = () => useContext(FlashNotificationContext);

const internalFlash = (containerId: string | number, ...args: FlashNotificationArgs) => {
  const [flashType, content, options = {}] = args;
  const optionsWithContainerId = { ...options, containerId };
  const { customTitle, hasCustomMessageStructure } = options;

  // If there is no content and no custom title, do not show the flash
  if (!(content || customTitle)) return;

  const toastContent = (
    <ToastNotificationWithStructuredContent
      {...(customTitle ? { customTitle } : { hasCustomMessageStructure })}
      kind={flashType}
      message={content}
    />
  );

  toast[flashType](toastContent, optionsWithContainerId);
};

/**
 * A version of flash that may be used outside a component context
 */
export const flash = (...args: FlashNotificationArgs) => {
  const toastContainerId = document.querySelector('.Toastify')?.id || '';
  return internalFlash(toastContainerId, ...args);
};

const FlashNotificationProvider = ({ children }: IFlashNotificationProvider): ReactElement => {
  const { id: containerId } = useReactInstance();
  const [flashLimit, setFlashLimit] = useState(1);

  const dismissAll = () => {
    toast.dismiss();
  };

  const clearWaitingQueue = () => {
    toast.clearWaitingQueue({ containerId });
  };

  return (
    <FlashNotificationContext.Provider
      value={{
        containerId,
        flash: (...args: FlashNotificationArgs) => internalFlash(containerId, ...args),
        dismissAll,
        clearWaitingQueue,
        flashLimit,
        setFlashLimit,
      }}
    >
      {children}
    </FlashNotificationContext.Provider>
  );
};

export { FlashNotificationProvider };
