import { dom } from '@fortawesome/fontawesome-svg-core';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const IconScene = ({ children }: Props) => {
  const targetClass = 'react-icon-provider';
  const elementsToWatch = document.getElementsByClassName(targetClass);
  Array.from(elementsToWatch).forEach((el) =>
    dom.i2svg({
      node: el,
      callback: () => {
        /* Do nothing. */
      },
    })
  );
  return <div className={targetClass}>{children}</div>;
};

export default IconScene;
