import { ValueSerializer } from 'type-route';

import { IntegrationProvider } from '../../../../types/IntegrationProvider';

export const IntegrationsProvider: ValueSerializer<IntegrationProvider> = {
  parse(provider) {
    return provider as IntegrationProvider;
  },
  stringify(value) {
    return String(value);
  },
};
