import { TranslateOptions } from 'i18n-js';

import { I18n } from '../../entrypoints/support';

const GLOBAL_PREFIX = 'react';

export default function initTranslations(prefixKey = '') {
  const prefix = prefixKey.length > 0 ? `${prefixKey}.` : prefixKey;

  return (key: string, options: TranslateOptions = {}): string => {
    return I18n.t(`${GLOBAL_PREFIX}.${prefix}${key}`, options);
  };
}
