import { ValueSerializer } from 'type-route';

export type BreadcrumbType = {
  href: string;
  name: string;
};

export const breadcrumb: ValueSerializer<BreadcrumbType> = {
  parse(crumb) {
    const route: { href: string; name: string } = JSON.parse(crumb);
    return route;
  },
  stringify(route) {
    return JSON.stringify(route);
  },
};
