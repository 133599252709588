import { css } from 'styled-components';

export const forceCapitalization = css`
  &:has(font) {
    font {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
`;
